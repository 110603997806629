<template>
  <v-app class="transparent">
    <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
        <base-v-component
        heading="Users List"
        />

        <base-material-card
        icon="mdi-account-group"
        title="Users List"
        class="px-5 py-3"
        >
        <v-simple-table>
          <filter-list
            :filters="filter"
            :on-change="getUsers">
          </filter-list>
            <tbody>
              <tr v-for="user in users" v-bind:key='user.id'>
                  <td >{{ user.id }}</td>
                  <td>{{ user.username }}</td>
                  <td>{{ user.email}}</td>
                  <td>{{ user.created_at }}</td>
                  <td>{{ user.updated_at }}</td>
                  <td v-if="$auth.isAdmin()">
                      <v-btn color="ma-2 error float-right" fab x-small dark  @click="openDeleteDialog(user)">
                          <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                      <v-btn color=" ma-2 info float-right" fab x-small dark :to="'/users/update/' + user.id">
                          <v-icon>mdi-account-edit</v-icon>
                      </v-btn>

                  </td>
              </tr>
            </tbody>
        </v-simple-table>
        </base-material-card>
        <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
            v-model="query.page"
            :length="pagination.length"
            :total-visible="7"
            @input="getUsers"
            @next="getUsers"
            @previous="getUsers"
        ></v-pagination>
        </div>
      <delete-dialog ref="deleteDialog" :on-delete="deleteUser"/>

        <div class="py-3"/>
        <v-btn v-if="$auth.isAdmin()" x-large fab dark fixed right bottom color="blue"  to="/users/create">
          <v-icon large>mdi-plus</v-icon>
        </v-btn>
    </v-container>
  </v-app>
</template>


<script>
  import DeleteDialog from "../../components/base/DeleteDialog";
  import FilterList from "../../components/base/FilterList";

  export default {
    name: "Users",
    components: {FilterList, DeleteDialog},
    created() {
      this.getUrlParams();
      this.getUsers();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getUsers();
      }
    },
    data() {
      return {
        users: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "username",
            value: "",
            type: "text_field"
          },
          {
            name: "email",
            value: "",
            type: "text_field",
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
          {
            name: "updated_at",
            value: "",
            type: "text_field"
          },
        ],
        items:[
          {
            text: 'Dashboard',
            disabled: false,
            href: '/',
          },
          {
            text: 'Users',
            disabled: true,
            href: '#',
          }
        ]
      }
    },
    methods: {
      getUsers() {
        this.$authApi.get(`/users`, {
          params: this.query
        }).then(res => {
          this.users = res.data.data.data;
          this.pagination.length = res.data.data.last_page;
          this.pagination.total = res.data.data.total;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "username",
              value: "",
              type: "text_field"
            },
            {
              name: "email",
              value: "",
              type: "text_field",
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
            {
              name: "updated_at",
              value: "",
              type: "text_field"
            }
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      },
      deleteUser (user) {
        this.$authApi.delete(`/users/${user.id}`).then(res => {
          this.getUsers()
        })
      },
      openDeleteDialog(user) {
        this.$refs.deleteDialog.openDialog(user);
      },
    }
  }
</script>

<style scoped>

</style>
